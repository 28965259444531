import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Icon from './Icon';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

export default ({ messages, setMessages, openCart }) => {
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        if (messages.length > 0) {
            setOpen(!open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages])
    const cartMessage = messages[0] === "Item added to cart";
    function handleExited() {
        const updatedMessages = [...messages];
        updatedMessages.splice(0, 1);
        setMessages(updatedMessages)
    }
    function handleClose(event, reason) {
        if (reason !== "clickaway") {
            setOpen(false);
        }
    }
    function renderMessageContent() {
        if (messages[0]) {
            if (cartMessage) {
                return messages[0]
            }
            return (
                <>
                    <div className='snackbar-icon'><Icon iconName="check" width={18} /></div>
                    <div>{messages[0]}</div>
                </>
            )
        }
        return null;
    }
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            action={
                <>
                    {cartMessage && <div>
                        <button className='btn' onClick={() => {
                            openCart();
                            setOpen(false);
                        }}>VIEW</button>
                    </div>}
                    <div>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <Icon iconName="times" width={20} />
                        </IconButton>
                    </div>
                </>
            }
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
            message={renderMessageContent()}
            onExited={handleExited}
            TransitionComponent={SlideTransition}
        />
    );
}