import React from "react";
import { Link, navigate } from "gatsby";
import Drawer from "@material-ui/core/Drawer";
import useFirebase from "../useFirebase";
import CartItem from "./CartItem";
import Logo from "./Logo";
import SocialButtons from "./SocialButtons";
import Icon from "./Icon";
import basketPlaceholder from "../images/basket_placeholder.svg";
import {
  getLocalStorage,
  AUTH_USER,
  toCurrency,
  ZIP_CODE,
  handleLocalStateLogout,
  unexpectedErrorFunction,
  removeLocalStorage,
} from "../utils";
import {
  VALID_ZIP_CODES,
  FARM_ROUTE,
  FOOD_ROUTE,
  RECIPES_ROUTE,
} from "../constants";
import ZipMenu from "./ZipMenu";
import TempZipCodeForm from "./TempZipCodeForm";

const leftNavItems = [
  {
    label: "FRESH FOOD",
    to: FOOD_ROUTE,
  },
  {
    label: "FOOD SOURCES",
    to: FARM_ROUTE,
  },
];

export default ({
  cartItems,
  updateQty,
  removeFromCart,
  checkoutPage,
  homePage,
  drawerOpen,
  setDrawerOpen,
}) => {
  const [zipMenuOpen, setZipMenuOpen] = React.useState(false);
  const [zipCode, setZipCode] = React.useState(getLocalStorage(ZIP_CODE) || "");
  const firebase = useFirebase();
  function handleCartClick() {
    setDrawerOpen("cart");
  }
  function handleClose() {
    setDrawerOpen("");
  }
  function clearZip() {
    setZipCode("");
    removeLocalStorage(ZIP_CODE);
  }
  function handleLogout() {
    firebase
      .auth()
      .signOut()
      .then(() => {
        handleLocalStateLogout();
        navigate("/", {
          state: {
            snackbarMessages: ["Logged out successfully"],
          },
        });
      })
      .catch(unexpectedErrorFunction);
  }
  const subTotal = cartItems.reduce((a, b) => {
    return a + b.qty * b.itemDetails.price;
  }, 0);
  const authUser = getLocalStorage(AUTH_USER);
  const sideNavItems = authUser
    ? [
        ...leftNavItems,
        {
          label: "RECIPES",
          to: RECIPES_ROUTE,
        },
        {
          label: "YOUR ACCOUNT",
          to: "/account/favorites/",
        },
        {
          label: "LOG OUT",
          handleClick: handleLogout,
        },
      ]
    : [
        ...leftNavItems,
        {
          label: "RECIPES",
          to: RECIPES_ROUTE,
        },
        {
          label: "LOG IN",
          to: "/login/",
        },
        {
          label: "SIGN UP",
          to: "/signup/",
        },
      ];
  function renderCheckout() {
    if (zipCode.length < 5) {
      return (
        <div className="text-left">
          <TempZipCodeForm setZipCode={setZipCode} />
        </div>
      );
    } else if (VALID_ZIP_CODES.includes(zipCode)) {
      if (authUser) {
        return (
          <Link
            to="/checkout/"
            className="btn primary-btn full-width margin-bottom-2"
          >
            <strong>CHECKOUT ${toCurrency(subTotal)}</strong>
          </Link>
        );
      }
      return (
        <Link
          to="/signup/"
          state={{ redirectTo: "/checkout/" }}
          className="btn primary-btn full-width margin-bottom-2"
          onClick={() => setDrawerOpen("")}
        >
          <strong>CHECKOUT ${toCurrency(subTotal)}</strong>
        </Link>
      );
    }
    return (
      <div>
        <p className="text-error">Sorry, we don't deliver to your ZIP</p>
        <button
          className="btn full-width white-btn small-btn"
          onClick={clearZip}
        >
          CLICK TO EDIT ZIP ({zipCode})
        </button>
      </div>
    );
  }
  function renderLeftNav() {
    if (checkoutPage) {
      return (
        <a href="/" className="logo">
          <Logo />
        </a>
      );
    }
    return (
      <>
        <Link to="/" className="logo">
          <Logo />
        </Link>
        {leftNavItems.map(({ label, to }, index) => {
          return (
            <Link
              key={`nav-link-${index}`}
              to={to}
              className="nav-link hide-nav"
              activeClassName="active"
            >
              {label}
            </Link>
          );
        })}
      </>
    );
  }
  return (
    <>
      <div className="link-container">{renderLeftNav()}</div>
      {!checkoutPage && (
        <>
          <div className="link-container flex-end">
            {!homePage && (
              <ZipMenu
                zipCode={zipCode}
                setZipCode={setZipCode}
                zipMenuOpen={zipMenuOpen}
                setZipMenuOpen={setZipMenuOpen}
              />
            )}
            {typeof window !== "undefined" && (
              <button
                className={`btn cart-btn${
                  cartItems.length > 0 ? " has-items" : ""
                }`}
                onClick={handleCartClick}
              >
                <span>
                  <Icon iconName="cart" width={22} />
                </span>
                <span className="cart-count">{cartItems.length}</span>
              </button>
            )}
          </div>
          <button
            className="btn nav-menu-btn nav-btn"
            onClick={() => setDrawerOpen("menu")}
          >
            <span>
              <Icon iconName="menuBars" width={24} />
            </span>
          </button>
          <Drawer
            anchor="right"
            open={drawerOpen === "menu"}
            onClose={handleClose}
          >
            <div className="menu-drawer text-center">
              <div>
                <div className="logo-container">
                  <Link to="/" onClick={handleClose}>
                    <Logo />
                  </Link>
                </div>
                <ul>
                  {sideNavItems.map(({ to, label, handleClick }) => {
                    return (
                      <li key={`drawer-${label}`} className="drawer-menu-item">
                        {handleClick ? (
                          <button className="btn" onClick={handleClick}>
                            {label}
                          </button>
                        ) : (
                          <Link to={to} onClick={handleClose}>
                            {label}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <SocialButtons altClass="alt" />
            </div>
          </Drawer>
          <Drawer
            anchor="right"
            open={drawerOpen === "cart"}
            onClose={handleClose}
          >
            <div className="drawer text-center">
              <div>
                <div className="logo-container heading">
                  <h3 className="text-primary">YOUR CART</h3>
                  <button className="btn" onClick={handleClose}>
                    <Icon width={18} iconName="times" />
                  </button>
                </div>
                <div className="add-padding">
                  {cartItems.length > 0 ? (
                    <div>
                      <ul>
                        {cartItems.map((item, index) => {
                          return (
                            <li key={`cart-item-${index}`}>
                              <CartItem
                                itemDetails={item.itemDetails}
                                qty={item.qty}
                                updateQty={updateQty}
                                removeFromCart={removeFromCart}
                                index={index}
                              />
                            </li>
                          );
                        })}
                      </ul>
                      <div className="total-container">{renderCheckout()}</div>
                    </div>
                  ) : (
                    <div className="text-center add-padding">
                      <div className="add-padding-2">
                        <img
                          className="empty-cart-img"
                          width={120}
                          height={120}
                          src={basketPlaceholder}
                          alt="Empty basket"
                        />
                      </div>
                      <p>
                        Your cart looks empty.{" "}
                        <Link onClick={handleClose} to={FOOD_ROUTE}>
                          Let's fill it up!
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
};
