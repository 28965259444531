import React from 'react';
import Icon from './Icon';
import { EMAIL_ADDRESS } from '../constants';

export default ({ altClass=''}) => (
    <div className={`social-btns ${altClass}`}>
        <a href='https://www.facebook.com/usetillsocial/' target="_blank" rel="noopener noreferrer" title="Follow us on Facebook">
            <Icon iconName='facebook' width={24} />
        </a>
        <a href="https://www.instagram.com/usetillsocial/" target="_blank" rel="noopener noreferrer" title="Follow us on Instagram">
            <Icon iconName='instagram' width={28} />
        </a>
        <a href={`mailto:${EMAIL_ADDRESS}`} title={`Email us at ${EMAIL_ADDRESS}`}>
            <Icon iconName='email' width={24} />
        </a>
    </div>
)