import React from 'react';
import { setLocalStorage, ZIP_CODE } from '../utils';
import { FOOD_ROUTE } from '../constants';

export default ({ submitAction, btnText, btnFlexBasis, zipCode, setZipCode, placeholder }) => {
    const [hasError, setHasError] = React.useState(false);
    const ref = React.useRef();
    function handleChange({ target: { value } }) {
        if(/^\d{0,5}$/.test(value)) {
            setZipCode(value);
        }
        if(value.length === 5) {
            setHasError(false);
        }
    }
    function handleBlur() {
        setHasError(false);
    }
    function handleSubmit(e) {
        e.preventDefault();
        if (zipCode.length !== 5) {
            setHasError(true);
            ref.current.focus();
        }
        else {
            setHasError(false);
            submitAction();
            setLocalStorage(ZIP_CODE, zipCode);
        }
    }
    return (
        <form action={FOOD_ROUTE} className={`grid-container zip-grid${hasError ? ' has-error' : ''}`} onSubmit={handleSubmit}>
            <div className={`grid-item input-grid-item`}>
                <input
                    className='form-input' 
                    placeholder={placeholder}
                    name='zip' 
                    type='tel'
                    value={zipCode}
                    onChange={handleChange}
                    ref={ref}
                    onBlur={handleBlur}
                />
            </div>
            <div className='grid-item btn-grid-item' style={{flexBasis: btnFlexBasis}}>
                <button type="submit" className='btn primary-btn'>{btnText}</button>
            </div>
        </form>
    )
}