import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default ({ children, maxWidth, ...otherProps }) => {
    return (
      <Dialog 
        {...otherProps} 
        TransitionComponent={Transition} 
        PaperProps={{style: {margin: 12, maxWidth, width: '100%'}}}
        fullWidth
      >{children}</Dialog>
    )
}