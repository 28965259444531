import React from 'react';
import GatsbyImage from 'gatsby-image';
import Icon from './Icon';
import { toCurrency } from '../utils';

export default ({ itemDetails, updateQty, removeFromCart, qty, index }) => {
    return (
        <div className='paper cart-item-paper'>
            <div className='cart-item-container'>
                <div className='img-container'>
                    <GatsbyImage fluid={itemDetails.productImage.childImageSharp.fluid} />
                </div>
                <div className='body'>
                    <p><strong>{itemDetails.name}</strong></p>
                    <p className='text-muted'>{itemDetails.farmName}</p>
                </div>
                <div className='close-container'>
                    <button className='btn' onClick={() => removeFromCart(index)}>Remove</button>
                </div>
            </div>
            <div className='total'>
                <div className='qty-container'>
                    <div>
                        <button className={`btn${qty === 1 ? ' disabled' : ''}`} onClick={() => updateQty(index, -1)} disabled={qty === 1}>
                            <Icon width={16} iconName="minus" />
                        </button>
                    </div>
                    <div className='qty'>{qty}</div>
                    <div>
                        <button className={`btn${qty >= itemDetails.qtyAvailable ? ' disabled' : ''}`} onClick={() => updateQty(index, 1)} disabled={qty >= itemDetails.qtyAvailable}>
                            <Icon width={16} iconName="plus" />
                        </button>
                    </div>
                </div>
                <div>
                    <strong>{'$'}{toCurrency(qty * itemDetails.price)}</strong>
                </div>
            </div>
        </div>
    )
}