export const VALID_ZIP_CODES = ["35213", "35209", "35243", "35242", "35223", "35216", "35222", "35233", "35203", "35205", "35226", "35244"];
export const GOOGLE_MAPS_SCRIPT_ID = "google-maps-script-loader";
export const ORDER_TOTAL_ITEMS = [
    {
        key: "deliveryFee",
        label: "Delivery fee"
    },
    {
        key: "bagFee",
        label: "Insulated tote bag"
    },
    {
        key: "tipAmount",
        label: "Driver tip"
    },
    {
        key: "serviceFee",
        label: "Service fee"
    }
];
export const FOOD_ROUTE = "/food/";
export const FARM_ROUTE = "/sources/";
export const RECIPES_ROUTE = "/recipes/";
export const BASE_URL = "https://usetill.com";
export const EMAIL_ADDRESS = "howdytill@usetill.com";
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const DELIVERY_WINDOW = "between 8:00 AM and 12:00 PM";