import React from 'react';
import Icon from './Icon';
import { VALID_ZIP_CODES } from '../constants';
import ZipForm from './ZipForm';

export default ({ zipCode, setZipCode, zipMenuOpen, setZipMenuOpen }) => {
    function handleClose() {
        setZipMenuOpen(false);
    }
    function handleClick() {
        setZipMenuOpen(true);
    }
    const validZip = VALID_ZIP_CODES.includes(zipCode);
    let button;
    let text;
    if (typeof window === "undefined") {
        return null;
    }
    else if (zipCode.length !== 5) {
        text = <p className='caption text-muted'><Icon iconName='mapMarker' width={14} /> Please enter your 5-digit ZIP code</p>;
        button = (
            <button className='btn nav-btn' onClick={handleClick}>
                <span><Icon iconName='mapMarker' width={26} /></span>
                <span className='nav-btn-text hide-nav'>ENTER ZIP CODE</span>
            </button>
        )
    }
    else if (validZip) {
        text = <p className='text-primary caption'>Great, we deliver to your ZIP code! <span role="img" aria-label="thumbs-up">👍</span></p>
        button = (
            <button className='btn nav-btn' onClick={handleClick}>
                <span><Icon iconName='mapMarkerCheck' width={26} /></span>
                <span className='nav-btn-text hide-nav'>ZIP: {zipCode}</span>
            </button>
        )
    }
    else {
        text = <p className='text-error caption'>Sorry, we don't deliver to this ZIP <span role="img" aria-label="sad-face">😥</span></p>
        button = (
            <button className='btn nav-btn error' onClick={handleClick}>
                <span><Icon iconName='mapMarkerTimes' width={26} /></span>
                <span className='nav-btn-text hide-nav'>ZIP: {zipCode}</span>
            </button>
        )
    }
    return (
        <div className={`menu-container${zipMenuOpen ? ' open' : ''}`}>
            {button}
            <div className='menu'>
                    {text}
                    <ZipForm
                        btnFlexBasis={80}
                        btnText="CLOSE"
                        zipCode={zipCode}
                        setZipCode={setZipCode}
                        submitAction={handleClose}
                        placeholder='ZIP code'
                    />
            </div>
            <div className='overlay' role='button' onClick={handleClose} tabIndex="0" onKeyDown={handleClose}/>
        </div>
    )
}