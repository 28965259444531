import firebase from 'firebase/app';
import 'firebase/auth';
import { FIREBASE_CREDENTIALS } from './config';

let instance = null;
export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) {
        return instance;
    }
    instance = firebase.initializeApp(FIREBASE_CREDENTIALS);
    return instance;
  }
  return null;
}