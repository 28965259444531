const GOOGLE_MAPS_API_KEY = "AIzaSyAYy86deOlFMzz0QndetDgmIb3bx0hnsDs";
const GOOGLE_RECAPTCHA_SITE_KEY = '6Le0QdgZAAAAAICC7qzrdUBBUL-54RlJy1BH0NtA';
let BASE_PATH;
let STRIPE_PUBLISHABLE_KEY;
let FIREBASE_CREDENTIALS;
if (process.env.NODE_ENV === "development") {
    BASE_PATH = "http://localhost:8080";
    STRIPE_PUBLISHABLE_KEY = "pk_test_mus6OR6Tc0vXXRZRQwHjCAhu";
    FIREBASE_CREDENTIALS = {
        apiKey: "AIzaSyCTYpfM4muuwqfjTRMHtpM-4OdkZLCyquQ",
        authDomain: "usetill-two.firebaseapp.com",
        databaseURL: "https://usetill-two.firebaseio.com",
        projectId: "usetill-two",
        storageBucket: "usetill-two.appspot.com",
        messagingSenderId: "15718255101",
        appId: "1:15718255101:web:6bf03bf4e11adbcbdcf194",
        measurementId: "G-JQR9BX8RJF"
    }
}
else {
    BASE_PATH = "https://use-till.ue.r.appspot.com";
    STRIPE_PUBLISHABLE_KEY = "pk_live_I6RCZ5iRS3Jgpjx0GqGM4RTI";
    FIREBASE_CREDENTIALS = {
        apiKey: "AIzaSyAYy86deOlFMzz0QndetDgmIb3bx0hnsDs",
        authDomain: "use-till.firebaseapp.com",
        databaseURL: "https://use-till.firebaseio.com",
        projectId: "use-till",
        storageBucket: "use-till.appspot.com",
        messagingSenderId: "437954279011",
        appId: "1:437954279011:web:84ee88e283547c06e4ea56",
        measurementId: "G-YRMJFNT5WP"
    }
}

module.exports = {
    BASE_PATH, STRIPE_PUBLISHABLE_KEY, FIREBASE_CREDENTIALS, GOOGLE_MAPS_API_KEY, GOOGLE_RECAPTCHA_SITE_KEY
}