import axios from 'axios';
import { get } from 'lodash';

export const AUTH_USER = "AUTH_USER";
export const CART = "CART";
export const ZIP_CODE = "ZIP_CODE";
export const FAVORITE_FARMS = "FAVORITE_FARMS";
export const FAVORITE_PRODUCTS = "FAVORITE_PRODUCTS";
export const FAVORITES_TIMESTAMP = "FAVORITES_TIMESTAMP";

export const getFirstName = ({ displayName }) => {
    if (displayName) {
        return displayName.split(' ')[0];
    }
    return ''
}

const isBrowser = () => typeof window !== "undefined";
export const getLocalStorage = itemName => {
    if (isBrowser()) {
        const item = window.localStorage.getItem(itemName);
        return item ? JSON.parse(item) : null;
    }
    return null;
}
export const setLocalStorage = (itemName, item) => {
    window.localStorage.setItem(itemName, JSON.stringify(item));
}
export const removeLocalStorage = itemName => {
    window.localStorage.removeItem(itemName);
}

export const generateID = name => name.replace(/\W/gi, '-').toLowerCase();

export const toCurrency = num => `${(num/100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

export const getCartItemInfo = cartItems => {
    return cartItems.reduce((a, { itemDetails, qty }, index) => {
            if (itemDetails.priceOptionItemName) {
                if (a.hasOwnProperty(itemDetails.itemID)) {
                    a[itemDetails.itemID].qty += qty;
                    a[itemDetails.itemID][itemDetails.priceOptionItemName] = index;
                }
                else {
                    a[itemDetails.itemID] = { qty };
                    a[itemDetails.itemID][itemDetails.priceOptionItemName] = index;
                }
            }
            else {
                a[itemDetails.itemID] = { qty, index };
            }
            return a;
        }, {});
}

export const currencyRegEx = /^(\d{1,3}(,\d{3})*|(\d+))(\.\d{2})$/;
export function getPriceInCents(price) {
    return parseInt(price.replace(/\D/g,''));
}
export const formatPhoneNumber = str => str ? `(${str.slice(2,5)}) ${str.slice(5,8)}-${str.slice(8,12)}` : '';

export const handleLocalStateLogout = () => {
    removeLocalStorage(AUTH_USER);
    removeLocalStorage(FAVORITE_FARMS);
    removeLocalStorage(FAVORITE_PRODUCTS);
    removeLocalStorage(FAVORITES_TIMESTAMP);
}
export const getAxiosErrorMessage = (err) => {
    return get(err, 'response.data.error') || 'System error';
}
export const unexpectedErrorFunction = (err) => {
    if (!err.hasOwnProperty('isCancelled')) {
        window.alert(`An error has occurred - ${getAxiosErrorMessage(err)}. Please refresh your browser; you may also need to log out and log back in again.`);
    }
}
export const makeCancellable = (promise) => {
  let hasCancelled_ = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => hasCancelled_ ? reject({isCancelled: true}) : resolve(val),
      error => hasCancelled_ ? reject({isCancelled: true}) : reject(error)
    );
  });
  return {
    promise: wrappedPromise,
    cancel() {
      hasCancelled_ = true;
    },
  };
};

export const fetchWithToken = (getIdToken, route, params='') => {
    return getIdToken.then(token => {
        return axios.get(`${route}?token=${token}${params}`);
    })
}

export const postWithToken = (getIdToken, route, body) => {
    return getIdToken.then(token => {
        return axios.post(`${route}?token=${token}`, body);
    })
}

export const setLocalStorageAuthUser = (authUser) => {
    return setLocalStorage(AUTH_USER, {
        displayName: authUser.displayName,
        email: authUser.email,
        phoneNumber: authUser.phoneNumber,
        uid: authUser.uid,
        emailVerified: authUser.emailVerified,
    })
}