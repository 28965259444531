import React from "react";
import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";
import { get } from "lodash";
import NavContent from "./NavContent";
import Logo from "./Logo";
import SocialButtons from "./SocialButtons";
import {
  getLocalStorage,
  setLocalStorage,
  CART,
  FAVORITE_FARMS,
  FAVORITE_PRODUCTS,
  handleLocalStateLogout,
  FAVORITES_TIMESTAMP,
  fetchWithToken,
  setLocalStorageAuthUser,
} from "../utils";
import "../stylesheets/index.sass";
import Snackbar from "./Snackbar";
import ConfirmationDialog from "./ConfirmationDialog";
import useFirebase from "../useFirebase";
import { BASE_PATH } from "../config";
import muktaRegular from "../../static/fonts/mukta-400.woff2";
import muktaBold from "../../static/fonts/mukta-700.woff2";
import ralewayBold from "../../static/fonts/raleway-900.woff2";
import WavefoundryLogo from "./WavefoundryLogo";

const DEFAULT_OG_DESCRIPTION =
  "Get connected to locally sourced, farm-fresh foods with till, Birmingham's only online farmers market.";

export const AuthContext = React.createContext(null);

export default ({
  children,
  pageTitle,
  homePage,
  addLayoutProps,
  checkoutPage,
  altOGImage,
  ogDescription,
  ogUrl = "/",
}) => {
  const {
    site: {
      siteMetadata: { title, description },
    },
  } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const firebase = useFirebase();
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    if (firebase) {
      firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          setUser(authUser);
          setLocalStorageAuthUser(authUser);
          const favoriteFarms = getLocalStorage(FAVORITE_FARMS);
          const favoriteProducts = getLocalStorage(FAVORITE_PRODUCTS);
          const favoritesTimestamp = getLocalStorage(FAVORITES_TIMESTAMP);
          // If there are no favorites stored locally, or if favorites haven't been refreshed in over 15 mins, refresh them
          if (
            !favoriteFarms ||
            !favoriteProducts ||
            !favoritesTimestamp ||
            favoritesTimestamp + 900000 < Date.now()
          ) {
            fetchWithToken(
              authUser.getIdToken(),
              `${BASE_PATH}/getUserFavorites`
            )
              .then(({ data }) => {
                setLocalStorage(FAVORITES_TIMESTAMP, Date.now());
                setLocalStorage(FAVORITE_FARMS, data.favoriteFarms);
                setLocalStorage(FAVORITE_PRODUCTS, data.favoriteProducts);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          setUser(null);
          handleLocalStateLogout();
        }
      });
    }
  }, [firebase]);

  const location = useLocation();
  const initSnackbarMessages = get(location, "state.snackbarMessages") || [];

  const [cartItems, setCartItems] = React.useState(getLocalStorage(CART) || []);
  const [qtyAlertDialogOpen, setQtyAlertDialogOpen] = React.useState(false);
  const [qtyAlertDialogMeta, setQtyAlertDialogMeta] = React.useState(null);
  const [snackbarMessages, setSnackbarMessages] = React.useState(
    initSnackbarMessages
  );
  const [drawerOpen, setDrawerOpen] = React.useState("");

  function addSnackbarMessage(message) {
    if (snackbarMessages.length < 3) {
      // Arbitrarily setting a limit of 3
      setSnackbarMessages([...snackbarMessages, message]);
    }
  }
  function addToCart(index, itemDetails) {
    const items = [...cartItems];
    if (
      index !== null &&
      items[index].itemDetails.qtyAvailable <= items[index].qty
    ) {
      setQtyAlertDialogOpen(true);
      setQtyAlertDialogMeta({
        header: "Limited quantity available",
        message:
          "Sorry, but we don't have any additional stock left for this item at the moment.",
      });
    } else {
      if (index !== null) {
        items[index].qty++;
      } else {
        items.push({ itemDetails, qty: 1 });
      }
      addSnackbarMessage("Item added to cart");
      setCartItems(items);
      setLocalStorage(CART, items);
    }
  }
  function removeFromCart(index) {
    const items = [...cartItems];
    items.splice(index, 1);
    setCartItems(items);
    setLocalStorage(CART, items);
  }
  function updateQty(index, amount) {
    const items = [...cartItems];
    items[index].qty += amount;
    setCartItems(items);
    setLocalStorage(CART, items);
  }
  const nav = (
    <NavContent
      cartItems={cartItems}
      updateQty={updateQty}
      removeFromCart={removeFromCart}
      checkoutPage={checkoutPage}
      homePage={homePage}
      drawerOpen={drawerOpen}
      setDrawerOpen={setDrawerOpen}
    />
  );
  const calculatedPageTitle = `till - ${pageTitle ||
    "Birmingham's online farmers market"}`;
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{calculatedPageTitle}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}safari-pinned-tab.svg`}
          color="#2e7d32"
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={muktaRegular}
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={muktaBold}
        />
        <link
          rel="preload"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          href={ralewayBold}
        />
        <meta name="theme-color" content="#377d33" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={ogDescription || DEFAULT_OG_DESCRIPTION}
        />
        <meta property="og:title" content={calculatedPageTitle} />
        <meta property="og:url" content={ogUrl} />
        <meta
          property="og:image"
          content={altOGImage || `${withPrefix("/")}og-image.jpg`}
        />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={calculatedPageTitle} />
        <meta
          property="twitter:description"
          content={ogDescription || DEFAULT_OG_DESCRIPTION}
        />
      </Helmet>
      <>
        {homePage ? (
          <>
            <nav className="home">{nav}</nav>
            {children}
          </>
        ) : (
          <AuthContext.Provider value={user}>
            <nav className={checkoutPage ? "checkout-page" : ""}>{nav}</nav>
            <main>
              <noscript>
                JavaScript is currently disabled in your browser. Most features
                of this website require JavaScript to work properly, so please
                enable JavaScript in your browser for full site functionality.
              </noscript>
              {addLayoutProps
                ? React.cloneElement(children, {
                    addToCart,
                    cartItems,
                    addSnackbarMessage,
                  })
                : children}
            </main>
          </AuthContext.Provider>
        )}
        <Snackbar
          messages={snackbarMessages}
          setMessages={setSnackbarMessages}
          openCart={() => setDrawerOpen("cart")}
        />
        <ConfirmationDialog
          {...qtyAlertDialogMeta}
          btnText="GOT IT"
          dialogOpen={qtyAlertDialogOpen}
          handleConfirm={() => {
            setQtyAlertDialogOpen(false);
          }}
          handleExited={() => {
            setQtyAlertDialogMeta(null);
          }}
        />
      </>
      {!checkoutPage && (
        <footer>
          <div className="footer-grid">
            <div className="links">
              <Link to="/" title="till">
                <Logo />
              </Link>
              <Link to="/contact/">Contact us</Link>
              <Link to="/about/">About us</Link>
              <Link to="/how-till-works/">How till works</Link>
              <Link to="/member-store/">Member store</Link>
              <Link to="/referrals/">Referrals</Link>
            </div>
            <SocialButtons />
          </div>
          <div className="caption-grid">
            <div className="legal">
              <span>© COPYRIGHT {new Date().getFullYear()} USETILL.COM</span>
            </div>
            <div>
              <a
                className="wf-logo"
                href="https://wavefoundry.io"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="icon">
                  <WavefoundryLogo />
                </div>
                <div>
                  <span>Powered by Wavefoundry</span>
                </div>
              </a>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};
