import React from 'react';
import { setLocalStorage, ZIP_CODE } from '../utils';

export default ({ setZipCode }) => {
    const [tempZipCode, setTempZipCode] = React.useState('');
    function handleChange({ target: { value } }) {
        if(/^\d{0,5}$/.test(value)) {
            setTempZipCode(value);
        }
    }
    function handleSubmit() {
        if(tempZipCode.length === 5) {
            setZipCode(tempZipCode)
            setLocalStorage(ZIP_CODE, tempZipCode);
        }
    }
    const disabled = tempZipCode.length !== 5;
    return (
        <>
            <label htmlFor="zip" >Please enter your ZIP code to checkout:</label>
            <div className='grid-container zip-grid-alt'>
                <div>
                    <input type="tel" className='form-input' placeholder="ZIP code" name="zip" onChange={handleChange} value={tempZipCode} />
                </div>
                <div>
                    <button disabled={disabled} className={`btn primary-btn full-width${disabled ? ' disabled' : ''}`} onClick={handleSubmit}>SAVE</button>
                </div>
            </div>
        </>
    )
}