import React from 'react';
import Dialog from './Dialog';
import Icon from './Icon';

export default ({dialogOpen, handleConfirm, header, message, btnText, handleExited, handleCancel, btnClass='primary-btn'}) => {
    function renderContent() {
        if (handleCancel) {
            return (
                <>
                    <button className='btn close-btn' onClick={handleCancel}>
                        <Icon width={18} iconName="times" />
                    </button>
                    <div className='flex-btn-container'>
                        <div><button className='btn small-btn white-btn full-width' onClick={handleCancel}>CANCEL</button></div>
                        <div><button className={`btn small-btn ${btnClass} full-width`} onClick={handleConfirm}>{btnText}</button></div>
                    </div>
                </>
            )
        }
        return <div><button className={`btn small-btn ${btnClass} full-width`} onClick={handleConfirm}>{btnText}</button></div>
    }
    return (
        <Dialog open={dialogOpen} maxWidth={450} disableBackdropClick onExited={handleExited}>
            <div className='add-padding-2'>
                <h5 className='margin-bottom-2'>{header}</h5>
                <p className='margin-bottom-2'>{message}</p>
                {renderContent()}
            </div>
        </Dialog>
    )
}